import { useMemo } from "react";
import useEvents from "@hooks/useEvents";
import { blobToImage } from "@utils/index";
import { useAnalytics, useAnalyticsContext } from "@contexts/Analytics";
import { Logger } from "@utils/logging";
import { mapperLanguageToSDKFormat } from "@utils/string";
import { useTranslation } from "react-i18next";
import useTemplateData from "@hooks/useTemplateData";
import store from "@store/index";
import { removeSpecialChars, removeSpecialCharsAndNumbers } from "@utils/formatting";

let passiveFaceLiveness, _result;

export default function usePassiveFaceLiveness() {
  const { i18n } = useTranslation();
  const { PassiveFaceLivenessSdk } = window["@combateafraude/passive-face-liveness"];

  const { sdkToken, templateData } = useTemplateData();
  const { analyticsTrackingId } = useAnalyticsContext();
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();
  const { emitEvent } = useEvents();
  const { personId, personCpf, personName } = store.variables.person;

  const pflStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "PFL";
      }),
    [templateData],
  );

  const linkPerson = useMemo(
    () => ({
      cpf: templateData?.data?.cpf?.trim(),
      name: templateData?.data?.name,
    }),
    [templateData],
  );

  const sdkOptions = {
    analyticsSettings: {
      trackingId: analyticsTrackingId,
    },
    token: sdkToken,
    language: mapperLanguageToSDKFormat(i18n?.language),
    environment: process.env.REACT_APP_SDK_ENV,
    environmentSettings: {
      disableVisibilityChangeSecurity: true,
      disableFaceDetectionSecurity: true,
    },
    capturerSettings: {
      disableAdvancedCapturing: true,
      disableVideoCapturing: true,
    },
    ...pflStep?.sdkOptions?.initialize,
  };

  const initialize = async (customOptions = undefined) => {
    _result = undefined;
    if (!passiveFaceLiveness && isOnTemplate()) {
      Logger.console("PFL initialize", pflStep);

      passiveFaceLiveness = new PassiveFaceLivenessSdk({ ...sdkOptions, ...customOptions });
    }

    if (!passiveFaceLiveness?.getIsInitialized()) {
      const permission = await passiveFaceLiveness.initPermissions();
      const suported = passiveFaceLiveness.isSupported();

      return permission && suported;
    }

    return true;
  };

  const reset = async () => {
    Logger.console("PFL reset", {
      _result,
      initialized: passiveFaceLiveness?.getIsInitialized(),
    });
    try {
      if (passiveFaceLiveness?.getIsInitialized()) {
        await passiveFaceLiveness.close();
        await passiveFaceLiveness.dispose();
      }
    } catch (error) {
      Logger.error("PFL reset", error);
    }
  };

  const hasResults = () => !!_result;

  const capture = async (containerNode, options) => {
    if (options.sdkOptions) {
      await reset();
      initialize(options.sdkOptions.initialize);
    }

    Logger.console("PFL capture", {
      _result,
      initialized: passiveFaceLiveness?.getIsInitialized(),
      hasContainerNode: !!containerNode,
      isOnTemplate: isOnTemplate(),
    });

    try {
      if (!passiveFaceLiveness?.getIsInitialized()) {
        await passiveFaceLiveness.initialize();

        ["capture_invalid", "capture_failed"].forEach((type) =>
          passiveFaceLiveness.addEventListener(type, (e) => {
            options?.onCaptureInvalid?.(e.detail.eventId);

            if (e?.detail?.eventId === "EXCEEDED_ATTEMPTS") {
              _result = { error: e.detail.eventId };
              options.onError(e.detail.eventId);
            }

            emitEvent({ code: `SDK_${type}`.toUpperCase(), detail: e?.detail || {} });
          }),
        );
      }
      await options.onInitialize();

      if (!!containerNode && isOnTemplate()) {
        logAnalyticsEventInfo(analyticsEvents.PFL_START, {
          _result,
        });

        _result = await passiveFaceLiveness.capture(
          containerNode,
          [
            {
              mode: pflStep?.sdkOptions?.capture?.captureSettings?.mode || "manual",
              attempts: 0,
              duration: pflStep?.sdkOptions?.capture?.captureSettings?.automaticCaptureTimeoutInSeconds ?? 30,
            },
            {
              mode: "manual",
              attempts: 0,
              duration: 0,
            },
          ],
          {
            captureSettings: {
              ...pflStep.sdkOptions?.capture?.captureSettings,
              ...options?.sdkOptions?.capture?.captureSettings,
            },
            personData: {
              personID: linkPerson?.cpf || personId || removeSpecialChars(personCpf),
              cpf: linkPerson?.cpf || personId || removeSpecialChars(personCpf),
              name: personName ?? linkPerson.name,
            },
          },
        );

        if (!!_result) {
          logAnalyticsEventInfo(analyticsEvents.PFL_CLOSE, {
            _result,
          });

          Logger.console("PFL capture results", {
            _result,
          });

          await options.onGetResults(_result);
        } else {
          logAnalyticsEventInfo(analyticsEvents.PFL_ERROR, {
            _result,
          });
          options.onError();
        }
      } else {
        Logger.error("PFL capture", {
          initialized: passiveFaceLiveness?.getIsInitialized(),
          containerNode,
        });
        logAnalyticsEventInfo(analyticsEvents.PFL_ERROR, {
          message: "PFL capture called without container node or not initialized",
          initialized: passiveFaceLiveness?.getIsInitialized(),
          containerNode,
        });
        options.onError();
      }
    } catch (error) {
      logAnalyticsEventInfo(analyticsEvents.PFL_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
      Logger.error("PFL capture", error);
      options.onError();
    }
  };

  const isOnTemplate = () => !!pflStep;

  const getImagePreview = (ignoreBlob = false) => {
    logAnalyticsEventInfo(analyticsEvents.PFL_INFO, {
      _result,
      step: "getImagePreview",
      ignoreBlob,
    });

    if (!_result) return "";

    if (_result.blob?.type?.startsWith("image/") && !ignoreBlob) return blobToImage(_result.blob);

    return _result.imageUrl;
  };

  const getVideoFileName = () => {
    logAnalyticsEventInfo(analyticsEvents.PFL_INFO, {
      _result,
      step: "getVideoFileName",
    });

    if (!_result || !_result?.file) return "";

    return _result.file;
  };

  const isExceededAttemptsError = () => _result?.error === "EXCEEDED_ATTEMPTS";

  return {
    isExceededAttemptsError,
    initialize,
    isOnTemplate,
    reset,
    hasResults,
    capture,
    getImagePreview,
    getVideoFileName,
  };
}
