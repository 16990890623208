import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { observer } from "mobx-react-lite";
import { LockOutlined } from "@ant-design/icons";
import { Regex, removeSpecialChars } from "@utils/formatting";
import InputMask from "react-input-mask";
import { useEffect, useState } from "react";
import { validateCPF } from "@utils/validations";
import store from "@store/index";
import { requestAction } from "../services";
import { useAnalytics } from "@contexts/Analytics";

const CPFValidation = (props) => {
  const { t } = useTranslation();
  const [cpf, setCpf] = useState("");
  const [error, setError] = useState(true);
  const [canNotGoFoward, setCanNotGoFoward] = useState(true);
  const { goForward } = store.navigation;
  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const {
    general: { token },
    person: personStore,
    setImmutableVariable,
  } = store.variables;
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();

  const shadowColor = `#${getComputedStyle(document.documentElement)
    .getPropertyValue("--color-illustrations")
    .trim()
    .replace("#", "")}20`;

  const isValidCPF = (value) => {
    if (!value) return false;
    return Regex.cpf.test(value) && validateCPF(removeSpecialChars(value));
  };

  const handleCPFChange = (e) => {
    const value = e.target.value;
    setCpf(value);
  };

  useEffect(() => {
    if (cpf.replace(/\D/g, "").length !== 11) {
      setError("");
      setCanNotGoFoward(true);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if (!isValidCPF(cpf)) {
        setError(
          t(
            "src.pages.onboarding.steps.allSteps.uniqueSteps.cpfValidation.error",
            "CPF inválido. Por favor, verifique e tente novamente.",
          ),
        );
        return;
      }

      setError("");
      startLoader({
        heading: t("src.pages.onboarding.steps.allSteps.uniqueSteps.cpfValidation.loaderMessage", "Validando CPF..."),
      });

      requestAction("REQUEST_ONBOARDING_DATA", token, {
        cpf: removeSpecialChars(cpf),
      }).then(async (res) => {
        const response = await res.json();
        if (!!response.data?.cpf) {
          personStore.setPersonCpf(response.data.cpf);
          personStore.setPersonId(response.data.cpf);
          setImmutableVariable("personCpf");
        }

        if (!!response.data?.personId) {
          personStore.setPersonCpf(response.data.personId);
          personStore.setPersonId(response.data.personId);
          setImmutableVariable("personCpf");
        }

        if (!!response.data?.name) {
          personStore.setPersonName(response.data.name);
        }

        if (!!response.data?.birthDate) {
          personStore.setPersonBirthDate(response.data.birthDate);
        }

        if (!!response.data?.email) {
          personStore.setPersonEmail(response.data.email);
        }

        if (!!response.data?.smsPhoneNumber) {
          personStore.setPersonPhoneNumber(response.data.smsPhoneNumber);
        }

        stopLoader();
        if (res.status === 200 && response?.isValid) {
          logAnalyticsEventInfo(analyticsEvents.CPF_VALIDATION_SUCCESS, {
            response: response,
          });
          goForward();
        } else {
          logAnalyticsEventInfo(analyticsEvents.CPF_VALIDATION_ERROR, {
            response: response,
          });
          setError(
            t(
              "src.pages.onboarding.steps.allSteps.uniqueSteps.cpfValidation.error",
              "CPF inválido. Por favor, verifique e tente novamente.",
            ),
          );
        }
      });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [cpf]);

  return (
    <Card>
      <Card.Body>
        {props?.illustration ? (
          <Card.Illustration src={props.illustration} />
        ) : (
          <div className="flex items-center justify-center rounded-full p-4 mt-6">
            <div
              className="rounded-full p-4"
              style={{
                backgroundColor: shadowColor,
                boxShadow: `0 4px 30px ${shadowColor}`,
              }}
            >
              <LockOutlined style={{ fontSize: "44px", color: "var(--color-illustrations)" }} />
            </div>
          </div>
        )}
        <div className="text-center mt-6">
          <Card.Heading
            text={
              props.heading ??
              t("src.pages.onboarding.steps.allSteps.uniqueSteps.cpfValidation.heading", "Confirme sua identidade")
            }
          />
          <Card.Subheading
            text={
              props.subheading ??
              t(
                "src.pages.onboarding.steps.allSteps.uniqueSteps.cpfValidation.subheading",
                "Antes de prosseguir, por favor, informe seu CPF.",
              )
            }
          />
        </div>

        <span className="mb-3 mt-6 text-base">
          {t("src.pages.onboarding.steps.allSteps.uniqueSteps.cpfValidation.label", "Digite o seu CPF")}
          <span className="text-red-600 ml-1">*</span>
        </span>
        <div className="w-full">
          <InputMask
            mask="999.999.999-99"
            className={`!rounded-md p-3.5 !font-medium w-full border ${error ? "border-red-600" : "border"}`}
            value={cpf}
            onChange={(event) => {
              handleCPFChange(event);
              setCpf(event.target.value);
            }}
          />
          <span id="cpf-error" className="text-red-600 text-sm mt-1 ml-1 block">
            {error}
          </span>
        </div>
      </Card.Body>
      <Card.NavBar disabledGoForward={canNotGoFoward} />
    </Card>
  );
};

export default observer(CPFValidation);
