import { action, computed, makeAutoObservable } from "mobx";
import type VariablesStore from ".";
import type { Countries, IncomeFrequency } from "../../types";

export interface Qsa {
  companyId: string;
  personType: string;
  name: string;
  cnpj: string;
  cpf: string;
  function: string;
  functionCode: string;
  date: string;
  isLegalRepresentative: boolean;
  legalRepresentativeCpf: string;
  legalRepresentativeName: string;
  legalRepresentativeCodeQualification: string;
  // I'm not sure if email and phone are returned by the API
  email?: string;
  phone?: string;
}

export interface QSAStatus {
  status: string;
  name: string;
}

export interface QsaInfo {
  main: boolean;
  name: string;
  cpf: string;
  email?: string;
  phone?: string;
  custom?: boolean;
  attorney?: boolean;
}

export type CompanyKybServices =
  | undefined
  | null
  | {
      creditReportId: string | null;
      businessIdentityId: string | null;
    };

export interface CompanySearchedSelected {
  id?: string | null;
  kybServices?: CompanyKybServices;
}

export interface CompanySearching {
  id: string;
  company: {
    status: any;
    number: string;
    address: string;
    country: string;
    name: string;
  };
  availableServices: {
    creditReportId?: string | null;
    businessIdentityId?: string | null;
  };
}

export default class CompanyVariablesStore {
  companyId?: string | null = undefined;
  companyCitiesList: {
    name: string;
  }[] = [];
  companyStatesList: {
    name: string;
    uf: string;
  }[] = [];
  companyCnpj = "";
  companyOfficialName = "";
  companyFantasyName = "";
  companyPhoneNumber = "";
  companyAddressStateUf = "";
  companyAddressState: string | null = null;
  companyAddressCity: string | null = null;
  companyAddressZipCode = "";
  companyAddressStreet = "";
  companyAddressNeighborhood = "";
  companyAddressNumber = "";
  companyAddressComplement = "";
  companyEmployeeName = "";
  companyEmployeeOccupation: string | null = null;
  companyEmployeeEmail = "";
  companyEmployeePhoneNumber = "";
  companyIncome = "";
  companyIncomeFrequency: IncomeFrequency = null;
  companyQsa: Qsa[] = [];
  companyQsaOriginal: Qsa[] = [];
  companyQsaInfo: QsaInfo[] = [];
  companyQsaInfoNumber = 0;
  companyQsaSelected: number | null = null;
  companyRepresentativeAttorneySelected: number | null = null;
  partnerOrigin: "data" | "manual" | "company" | null = null;
  companyQsaStatus: QSAStatus[] = [];
  nameForReferenceCompany = "";
  referenceAttorney?: {
    name?: string;
    cpf?: string;
  };
  companyCountry?: Countries = undefined;
  companyState?: string = undefined;
  companyName?: string = undefined;
  companyNumber?: string = undefined;
  companies: CompanySearching[] = [];
  companySearchedSelected: CompanySearchedSelected = {
    id: undefined,
    kybServices: {
      businessIdentityId: null,
      creditReportId: null,
    },
  };

  constructor(private variablesStore: VariablesStore) {
    makeAutoObservable(this, {
      setPartnerOrigin: action.bound,
      setCompanyQsaSelected: action.bound,

      isMonthlyIncome: computed,
      isAnnuallyIncome: computed,
      selectedQsa: computed,
      mainPartner: computed,
    });
  }

  setCompanyCountry = (companyCountry: Countries) => {
    this.companyCountry = companyCountry;
  };
  setCompanySearchedSelected = (companySearchedSelected: CompanySearchedSelected) => {
    this.companySearchedSelected = companySearchedSelected;
  };
  setCompanies = (companies: any) => {
    this.companies = companies;
  };
  setCompanyState = (companyState: string) => {
    this.companyState = companyState;
  };
  setCompanyName = (companyName: string) => {
    this.companyName = companyName;
  };
  setCompanyNumber = (companyNumber: string) => {
    this.companyNumber = companyNumber;
  };
  setCompanyId = (companyId: string) => {
    this.companyId = companyId;
  };
  setCompanyCitiesList = (companyCitiesList: CompanyVariablesStore["companyCitiesList"]) => {
    this.companyCitiesList = companyCitiesList;
  };
  setCompanyStatesList = (companyStatesList: CompanyVariablesStore["companyStatesList"]) => {
    this.companyStatesList = companyStatesList;
  };
  setCompanyCnpj = (companyCnpj: string) => {
    this.companyCnpj = companyCnpj;
  };
  setCompanyOfficialName = (companyOfficialName: string) => {
    this.companyOfficialName = companyOfficialName;
  };
  setCompanyFantasyName = (companyFantasyName: string) => {
    this.companyFantasyName = companyFantasyName;
  };
  setCompanyPhoneNumber = (companyPhoneNumber: string) => {
    this.companyPhoneNumber = companyPhoneNumber;
  };
  setCompanyAddressStateUf = (companyAddressStateUf: string) => {
    this.setCompanyAddressCity(null);
    this.companyAddressStateUf = companyAddressStateUf;
  };
  setCompanyAddressState = (companyAddressState: CompanyVariablesStore["companyAddressState"]) => {
    const uf = this.companyStatesList.find((state) => state?.name === companyAddressState)?.uf;
    if (uf) {
      this.setCompanyAddressStateUf(uf);
    }
    this.companyAddressState = companyAddressState;
  };
  setCompanyAddressCity = (companyAddressCity: CompanyVariablesStore["companyAddressCity"]) => {
    this.companyAddressCity = companyAddressCity;
  };
  setCompanyAddressStateInternational = (companyAddressState: string) => {
    this.companyAddressState = companyAddressState;
  };
  setCompanyAddressCityInternational = (companyAddressCity: string) => {
    this.companyAddressCity = companyAddressCity;
  };
  setCompanyAddressZipCodeInternational = (companyAddressZipCode: string) => {
    this.companyAddressZipCode = companyAddressZipCode;
  };
  setCompanyAddressZipCode = (companyAddressZipCode: string) => {
    this.companyAddressZipCode = companyAddressZipCode.replace(/\D/g, "");
  };
  setCompanyAddressStreet = (companyAddressStreet: string) => {
    this.companyAddressStreet = companyAddressStreet;
  };
  setCompanyAddressNeighborhood = (companyAddressNeighborhood: string) => {
    this.companyAddressNeighborhood = companyAddressNeighborhood;
  };
  setCompanyAddressNumber = (companyAddressNumber: string) => {
    this.companyAddressNumber = companyAddressNumber;
  };
  setCompanyAddressComplement = (companyAddressComplement: string) => {
    this.companyAddressComplement = companyAddressComplement;
  };
  setCompanyEmployeeName = (companyEmployeeName: string) => {
    this.companyEmployeeName = companyEmployeeName;
  };
  setCompanyEmployeeOccupation = (companyEmployeeOccupation: string) => {
    this.companyEmployeeOccupation = companyEmployeeOccupation;
  };
  setCompanyEmployeeEmail = (companyEmployeeEmail: string) => {
    this.companyEmployeeEmail = companyEmployeeEmail;
  };
  setCompanyEmployeePhoneNumber = (companyEmployeePhoneNumber: string) => {
    this.companyEmployeePhoneNumber = companyEmployeePhoneNumber;
  };
  setCompanyIncome = (companyIncome: string) => {
    this.companyIncome = companyIncome;
  };
  setCompanyIncomeFrequency = (companyIncomeFrequency: CompanyVariablesStore["companyIncomeFrequency"]) => {
    this.companyIncomeFrequency = companyIncomeFrequency;
  };
  setCompanyQsa = (companyQsa: Qsa[]) => {
    this.companyQsa = companyQsa;
  };
  setCompanyQsaOriginal = (companyQsaOriginal: Qsa[]) => {
    this.companyQsaOriginal = companyQsaOriginal;
  };
  setCompanyQsaInfo = (companyQsaInfo: CompanyVariablesStore["companyQsaInfo"]) => {
    this.companyQsaInfo = companyQsaInfo;
  };
  setNameForReferenceCompany = (nameForReferenceCompany: CompanyVariablesStore["nameForReferenceCompany"]) => {
    this.nameForReferenceCompany = nameForReferenceCompany;
  };
  setReferenceAttorney = (referenceAttorney: CompanyVariablesStore["referenceAttorney"]) => {
    this.referenceAttorney = referenceAttorney;
  };
  setCompanyQsaInfoNumber = (companyQsaInfoNumber: CompanyVariablesStore["companyQsaInfoNumber"]) => {
    this.companyQsaInfoNumber = companyQsaInfoNumber;
  };
  setCompanyQsaSelected = (companyQsaSelected: CompanyVariablesStore["companyQsaSelected"]) => {
    if ((!!companyQsaSelected || companyQsaSelected === 0) && this.partnerOrigin === "data") {
      const qsa = this.companyQsa[companyQsaSelected];

      this.setCompanyQsaInfo(
        this.companyQsa.map((qsa, index) => {
          return {
            main: index === companyQsaSelected,
            name: qsa.name,
            cpf: "",
            email: "",
            phone: "",
          };
        }),
      );

      if (this.variablesStore.person.personName?.length === 0) this.variablesStore.person.setPersonName(qsa.name);
      if (this.variablesStore.person.personCpf?.length === 0) this.variablesStore.person.setPersonCpf(qsa.cpf);
    } else {
      this.variablesStore.person.setPersonName("");
      this.variablesStore.person.setPersonCpf("");
    }

    this.companyQsaSelected = companyQsaSelected;
  };

  setCompanyRepresentativeAttorneySelected = (
    companyRepresentativeAttorneySelected: CompanyVariablesStore["companyRepresentativeAttorneySelected"],
  ) => {
    if (companyRepresentativeAttorneySelected !== null && this.partnerOrigin === "data") {
      this.setCompanyQsaInfo(
        this.companyQsa.map((qsa, index) => {
          if (index === companyRepresentativeAttorneySelected) {
            return {
              main: true,
              name: "",
              attorney: true,
              cpf: "",
              email: "",
              phone: "",
            };
          }
          return {
            main: false,
            name: qsa.name,
            cpf: "",
            email: "",
            phone: "",
          };
        }),
      );
    } else if (this.partnerOrigin === "company") {
      this.setCompanyQsaInfo([
        {
          main: true,
          name: "",
          attorney: true,
          cpf: "",
          email: "",
          phone: "",
        },
      ]);
    }

    this.companyRepresentativeAttorneySelected = companyRepresentativeAttorneySelected;
  };

  setPartnerOrigin(partnerOrigin: CompanyVariablesStore["partnerOrigin"]) {
    this.partnerOrigin = partnerOrigin;
  }

  setCompanyQsaStatus = (companyQsaStatus: CompanyVariablesStore["companyQsaStatus"]) => {
    this.companyQsaStatus = companyQsaStatus;
  };

  updateQsaInfo = (index: number, qsaInfo: QsaInfo) => {
    this.companyQsaInfo[index] = qsaInfo;
  };

  get isMonthlyIncome() {
    return this.companyIncomeFrequency === "monthly";
  }

  get isAnnuallyIncome() {
    return this.companyIncomeFrequency === "annually";
  }

  get selectedQsa(): Qsa | null {
    if ((!this.companyQsaSelected && this.companyQsaSelected !== 0) || this.partnerOrigin === "manual") return null;

    const selectedQsa = this.companyQsa.at(this.companyQsaSelected);

    if (!selectedQsa) return null;

    return selectedQsa;
  }

  get mainPartner(): QsaInfo | null {
    if (this.companyQsaInfo.length === 0) return null;

    const qsaInfo = this.companyQsaInfo.find((qsa) => qsa.main);

    if (!qsaInfo) return null;

    return qsaInfo;
  }
}
