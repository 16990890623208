import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import OptionButton from "@components/OptionButton";
import { useAnalytics } from "@contexts/Analytics";
import { observer } from "mobx-react-lite";
import Button from "@components/Button";
import store from "@store/index";

const I18N_BASE_PATH = "src.pages.onboarding.steps.sendDocumentType";

const SendDocumentType = (props) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { analyticsSteps, analyticsActions, logAnalyticsStepInfo, logAnalyticsActionInfo } = useAnalytics();
  const documentStore = store.variables.document;
  const { goForward } = store.navigation;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_SEND_DOCUMENT_TYPE);
  }, []);

  const handleSelectOption = (option) => {
    logAnalyticsActionInfo(
      analyticsActions.SELECT_DOCUMENT_TYPE,
      {
        option,
      },
      props.name,
    );
    documentStore.setSelectedSendDocumentType(option);

    if (option !== "picture") setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    documentStore.setSelectedSendDocumentType(undefined);
    setIsModalOpen(false);
  };

  const handleChoseFileType = (type) => {
    logAnalyticsActionInfo(
      analyticsActions.CHOOSE_FILE_TYPE,
      {
        type,
      },
      props.name,
    );
    documentStore.setSelectedDocumentFileDD(type);
  };

  return (
    <Card>
      <Card.Body>
        {isModalOpen ? (
          <>
            <Card.Heading
              text={
                props.modalDocumentHeading ??
                t(`${I18N_BASE_PATH}.components.chooseFileModal.attachFile`, "Anexar documento")
              }
            />
            <Card.Text
              small
              text={
                props.modalDocumentSubheading ??
                t(
                  `${I18N_BASE_PATH}.components.chooseFileModal.description`,
                  "Escolha o formato do arquivo que deseja anexar",
                )
              }
            />
            <div className="flex flex-col space-y-16">
              <div className="flex flex-col space-y-3 mb-4 pt-3">
                {props.sendDocumentTypeModal?.map(({ id, icon, title, description }, index) => (
                  <OptionButton
                    key={`send-document-type-option-${index}`}
                    icon={icon}
                    title={title}
                    description={description}
                    onClick={() => handleChoseFileType(id)}
                    selected={documentStore.selectedDocumentFileDD === id}
                    data-testid={`send-document-type-option-${index}`}
                  />
                ))}
              </div>
              <Button
                data-testid="send-document-type-button"
                size="md"
                onClick={() => {
                  if (documentStore.selectedDocumentFileDD) goForward();
                }}
                style={{
                  color: "var(--color-button-text) !important",
                  backgroundColor: "var(--color-button-background) !important",
                }}
                className={`mt-5 w-48 self-center px-3 ${
                  !documentStore.selectedDocumentFileDD &&
                  "!border-none !bg-gray-300 !shadow-none !outline-none continue-button-not-allowed"
                }`}
              >
                {props.modalDocumentButton ??
                  t(`${I18N_BASE_PATH}.components.chooseFileModal.attachFile`, "Anexar documento")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Card.Heading
              text={props.heading ?? t(`${I18N_BASE_PATH}.heading`, "Como deseja validar seu documento?")}
            />
            <Card.Subheading
              text={
                props.subheading ??
                t(`${I18N_BASE_PATH}.subheading`, "Você pode escolher entre as duas formas de validação")
              }
            />
            <div className="flex flex-col space-y-3 mb-4">
              {props.sendDocumentTypes?.map(({ id, icon, title, description }) => (
                <OptionButton
                  key={`send-document-type-${id}`}
                  icon={icon}
                  title={title}
                  description={description}
                  onClick={() => handleSelectOption(id)}
                  selected={documentStore.selectedSendDocumentType === id}
                  data-testid={`send-document-type-${id}`}
                />
              ))}
            </div>
          </>
        )}
      </Card.Body>
      <Card.NavBar
        disabledGoForward={
          !documentStore.selectedSendDocumentType ||
          (documentStore.selectedSendDocumentType === "upload" && !documentStore.selectedDocumentFileDD)
        }
        onClose={isModalOpen ? handleCloseModal : undefined}
      />
    </Card>
  );
};

export default observer(SendDocumentType);
