import React, { useEffect, useMemo, useState } from "react";
import { Form, Select } from "antd";
import classNames from "classnames";

import Card from "@components/Card";
import { FormCheckbox, FormSelect, FormSwitch } from "@components/Form";
import { FormRadioGroup } from "@components/Form/Form";
import { useAnalytics } from "@contexts/Analytics";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import { validateCNPJ, validateEmail, validateCPF } from "@utils/validations";
import { useTranslation } from "react-i18next";
import { Regex, removeSpecialChars } from "@utils/formatting";

import Text from "./CustomInputs/Text";
import Phone from "./CustomInputs/Phone";
import Email from "./CustomInputs/Email";
import Number from "./CustomInputs/Number";
import Password from "./CustomInputs/Password";
import CPF from "./CustomInputs/CPF";
import CNPJ from "./CustomInputs/CNPJ";
import Free from "./CustomInputs/Free";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const CustomForm = (props) => {
  const [shouldValidate, setShouldValidate] = useState(false);

  const { t } = useTranslation();

  const { analyticsSteps, logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions } = useAnalytics();

  const { phoneValidationPhoneInput2 } = usePhoneFormatter();

  const { setFormData, getFormData } = store.customForm;
  const { goForward } = store.navigation;

  const fields = useMemo(
    () =>
      props.fields
        ?.map((field) => {
          if (!field?.type) return null;
          if (field?.type === "input") {
            return {
              ...field,
              type: field?.inputType ?? "text",
            };
          }

          return field;
        })
        .filter((item) => item !== null) ?? [],
    [props.fields],
  );

  const logChangeAction = (field) => {
    let actionName;
    if (field?.required) {
      actionName = analyticsActions.REQUIRED_FIELD_CHANGED;
    } else {
      actionName = analyticsActions.OPTIONAL_FIELD_CHANGED;
    }

    logAnalyticsActionInfo(
      actionName,
      {
        fieldId: field?.id,
      },
      props.name,
    );
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_CUSTOM_FORM);
  }, [logAnalyticsStepInfo]);

  const allFields = {
    title: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <Card.Text {...field} {...props} className={classNames("!m-0", { "font-bold": field.bold })} />
        );
      },
    },
    switch: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <FormSwitch
            {...field}
            {...props}
            onChange={(value) => {
              logChangeAction(field);
              props?.onChange?.(value);
            }}
            showRequiredMessage={field?.required && shouldValidate && !props.value}
          />
        );
      },
    },
    checkbox: {
      validity: (field) =>
        field.required
          ? !!getFormData(props.formName, field.id) && getFormData(props.formName, field.id).length > 0
          : true,
      get component() {
        return (field, props) => (
          <FormCheckbox
            {...field}
            {...props}
            multiple
            onChange={(value) => {
              logChangeAction(field);
              props?.onChange?.(value);
            }}
            showRequiredMessage={field?.required && shouldValidate && !props.value}
          />
        );
      },
    },
    radio: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <FormRadioGroup
            onChange={(value) => {
              logChangeAction(field);
              props?.onChange?.(value);
            }}
            options={field.options}
            required={field?.required}
            value={props.value}
            showRequiredMessage={field?.required && shouldValidate && !props.value}
          />
        );
      },
    },
    select: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <FormSelect
            {...field}
            {...props}
            onChange={(value) => {
              logChangeAction(field);
              props?.onChange?.(value);
            }}
            options={undefined}
            prefixIcon={field.icon}
            showRequiredMessage={field?.required && shouldValidate && !props.value}
          >
            {field.options?.map((option, i) => (
              <Option value={option} key={`FIELD_${field.id}_OPTION_${i}`}>
                {option}
              </Option>
            ))}
          </FormSelect>
        );
      },
    },
    email: {
      validity: (field) => {
        const value = getFormData(props.formName, field.id);
        if (field.required && !value) return false;
        if (!field.required && !value) return true;

        return validateEmail(value);
      },
      get component() {
        return (field, props) => (
          <Email
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
            label={field.label ?? t("general.fields.email.label", "Email")}
            placeholder={field.placeholder ?? t("general.fields.email.placeholder", "Digite o seu email")}
          />
        );
      },
    },
    phone: {
      validity: (field) => {
        const value = getFormData(props.formName, field.id);
        if (field.required && !value) return false;

        return phoneValidationPhoneInput2(value, field.required);
      },
      get component() {
        return (field, props) => (
          <Phone
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
            label={field.label ?? t("general.fields.phoneNumber.label", "Telefone")}
            placeholder={field.placeholder ?? t("general.fields.phoneNumber.placeholder", "Digite o seu telefone")}
          />
        );
      },
    },
    password: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <Password
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
            label={field?.label ?? t("general.fields.password.label", "Senha")}
            placeholder={field?.placeholder ?? t("general.fields.password.placeholder", "Digite a sua senha")}
          />
        );
      },
    },
    number: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <Number
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
            label={field?.label ?? t("general.fields.password.label", "Senha")}
            placeholder={field?.placeholder ?? t("general.fields.password.placeholder", "Digite a sua senha")}
          />
        );
      },
    },
    text: {
      validity: (field) => (field.required ? !!getFormData(props.formName, field.id) : true),
      get component() {
        return (field, props) => (
          <Text
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
          />
        );
      },
    },
    cpf: {
      validity: (field) => {
        const value = getFormData(props.formName, field.id);

        if (field.required && !value) return false;

        return value ? Regex.cpf.test(value) && validateCPF(removeSpecialChars(value)) : true;
      },
      get component() {
        return (field, props) => (
          <CPF
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
            label={field?.label ?? t("general.fields.cpf.label", "CPF")}
            placeholder={field?.placeholder ?? t("general.fields.cpf.placeholder", "Digite o seu CPF")}
          />
        );
      },
    },
    cnpj: {
      validity: (field) => {
        const value = getFormData(props.formName, field.id);

        if (field.required && !value) return false;

        return value ? Regex.cnpj.test(value) && validateCNPJ(removeSpecialChars(value)) : true;
      },
      get component() {
        return (field, props) => (
          <CNPJ
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            validity={this.validity(field)}
            label={field?.label ?? t("general.fields.cnpj.label", "CNPJ")}
            placeholder={field?.placeholder ?? t("general.fields.cnpj.placeholder", "Digite o seu CNPJ")}
          />
        );
      },
    },
    free: {
      validity: () => {
        return true;
      },
      get component() {
        return (field, props) => (
          <Free
            {...field}
            {...props}
            onBlur={() => {
              logChangeAction(field);
              props?.onBlur?.();
            }}
            label={field?.label ?? t("general.fields.free.label", "Livre")}
            placeholder={field?.placeholder ?? t("general.fields.free.placeholder", "Digite...")}
          />
        );
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) setShouldValidate(true);
    else goForward();
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading text={props.heading ?? t(`${I18N_BASE_PATH}.duplicableSteps.customForm.heading`, "Formulário")} />
        <Card.Subheading
          text={
            props.subheading ??
            t(`${I18N_BASE_PATH}.duplicableSteps.customForm.subheading`, "Por favor, preencha os dados a seguir")
          }
        />
        <Form layout="vertical" className="mt-2 !space-y-6">
          {fields.map((field, index) =>
            field.type && allFields[field.type]
              ? React.createElement(allFields[field.type].component, {
                  ...field,
                  key: index,
                  value: getFormData(props.formName, field.id),
                  onChange: (value) => setFormData(props.formName, field.id, value),
                })
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!fields.every((field) => allFields[field.type]?.validity(field))}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(CustomForm);
