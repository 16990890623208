import { getIPAddress } from "@utils/index";
import { toJS } from "mobx";
import { useEffect, useMemo, useReducer } from "react";

import useDocumentDetector, { documentSides } from "@steps/DD/hooks/useDocumentDetector";
import useFaceAuth from "@steps/FA/hooks/useFaceAuth";
import useIProovFaceAuth from "@steps/FaceAuthIProov/hooks/useIProovFaceAuth";
import useLivenessClearSale from "@steps/LivenessClearSale/hooks/useLivenessClearSale";
import useIProovLiveness from "@steps/LivenessIProov/hooks/useIProovLiveness";
import usePassiveFaceLiveness from "@steps/PFL/hooks/usePassiveFaceLiveness";

import { useAnalytics, useAnalyticsContext } from "@contexts/Analytics";
import type { FormData } from "@store/customForm";
import store from "@store/index";
import { cleanObject } from "@utils/formatting";
import { sendFinalPost, unifyMultipleFilesKeys } from "../services";

import useEvents from "@hooks/useEvents";
import useTemplateData from "@hooks/useTemplateData";
import { useTranslation } from "react-i18next";
import { CompanyKybServices } from "@store/variables/company";

interface File {
  contentType?: string;
  id?: string;
  key?: string;
  type: string;
  bucket?: string;
  videoFileName?: string;
  data?: string;
}

interface Attributes {
  idType: string;
  phoneNumber: string;
  address: {
    country?: string;
    state: string | null;
    city: string | null;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;
    zipCode: string;
  } | null;
  annuallyBilling?: string;
  monthlyBilling?: string;
  acceptedTermsData: Array<{
    name: string;
    link: string;
    datetime: string;
    userAgent: string;
    userIp: string | undefined;
  }>;
  customForms?: FormData;
}

interface PFAttributes extends Attributes {
  error: {
    [key: string]: boolean;
  };
  personId: string;
  name: string;
  cpf: string;
  birthDate: string;
  motherName: string;
  fatherName: string;
  email: string;
  rg: string;
  rgIssuingAuthority: string;
  rgIssueDate: string;
  issueDate: string;
  rgIssueState: string;
  nit: string;
  plate: string;
  attorney: boolean;
  PFPFData: {
    cpf: string;
    name: string;
    email: string;
    phoneNumber: string;
  } | null;
  faceAuthenticator?: {
    personId: string;
    isMatch: boolean;
  };
}

interface PJAttributes extends Attributes {
  companyId?: string | null;
  cnpj: string;
  officialName: string;
  fantasyName: string;
  manualCompanyName?: string | null;
  manualCompanyNumber?: string | null;
  manualCompanyCountryCode?: string | null;
  qsa: Array<{
    main: boolean;
    name: string;
    cpf: string;
    nameForReferenceCompany: string;
    email?: string;
    phoneNumber?: string;
  }> | null;
  employee: {
    name: string;
    occupation?: string;
    email: string;
    phoneNumber: string;
  } | null;
  kybServices?: {
    creditReportId: string | null;
    businessIdentityId: string | null;
  };
}

interface SendFinalPostState {
  loading: boolean;
  error: boolean;
  success: boolean;
  errorData: any;
}

interface SetLoadingAction {
  type: "SET_LOADING";
}

interface SetErrorAction {
  type: "SET_ERROR";
  errorData: any;
}

interface SetSuccessAction {
  type: "SET_SUCCESS";
}

type Action = SetLoadingAction | SetErrorAction | SetSuccessAction;

function reducer(state: SendFinalPostState, action: Action): SendFinalPostState {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        errorData: null,
        loading: true,
        error: false,
        success: false,
      };
    case "SET_ERROR":
      return {
        ...state,
        errorData: action.errorData,
        loading: false,
        error: true,
        success: false,
      };
    case "SET_SUCCESS":
      return {
        ...state,
        errorData: null,
        loading: false,
        error: false,
        success: true,
      };
  }
}

const initialState = {
  loading: false,
  error: false,
  success: false,
  errorData: null,
};

interface SendFinalPostProps {
  postEventMessage: boolean;
}

const I18N_BASE_PATH = "src.pages.onboarding.steps.done";

export default function useSendFinalPost(props: SendFinalPostProps) {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();

  const { analyticsTrackingId, sessionId } = useAnalyticsContext();
  const { templateData } = useTemplateData();
  const { emitEvent } = useEvents();

  const userData = store.user;
  const documentData = store.variables.document;
  const companyData = store.variables.company;
  const personData = store.variables.person;
  const generalData = store.variables.general;
  const pfpfData = store.variables.pfpf;

  const navigationStore = store.navigation;
  const { start: startLoader } = store.ui.loading;

  const token = store.variables.general.token;

  const customFormData = store.customForm.formData;

  const documentDetector = useDocumentDetector();
  const passiveFaceLiveness = usePassiveFaceLiveness();
  const faceAuth = useFaceAuth();
  const livenessClearSale = useLivenessClearSale();
  const livenessIProov = useIProovLiveness();
  const faceAuthIProov = useIProovFaceAuth();

  const onboardingType = useMemo(() => templateData?.template.type, [templateData]);

  const personId = useMemo(() => templateData?.data?.cpf?.trim() ?? personData.personId, [templateData, personData]);

  const getMetadata = () => ({
    sessionId,
    analyticsTrackingId,
    browser: {
      userAgent: userData.metadata.userAgent,
      userAgentData: userData.metadata.userAgentData,
      browserName: userData.metadata.browserName,
      browserVersion: userData.metadata.browserVersion,
      userReadBrowserCompatibilityTerms: userData.readBrowserCompatibilityTerms,
    },
    location: userData.metadata.location,
    documentOrigin: documentData.selectedDocumentFileDD,
    documentIssuedCountry: documentData.documentIssuedCountry,
    companyCountry: companyData.companyCountry,
    partnerOrigin: companyData.partnerOrigin,
    extra: templateData?.metadata ? templateData.metadata : {},
  });

  const getFiles = async (options = { sdkFiles: false }) => {
    const filteredUploadedFiles = documentData.multipleUploadedFiles.filter(({ keys }) => keys.length > 1);

    const uploadedFiles = filteredUploadedFiles.map((filteredFile) =>
      documentData.multipleUploadedFiles.filter((multipleFile) => filteredFile.type !== multipleFile.type),
    )?.[0];

    let filesToPush: File[] = [];

    if (uploadedFiles && uploadedFiles?.length > 0) {
      filesToPush = uploadedFiles.map((file) => ({
        contentType: file.contentType,
        id: file.title ?? String(file.id),
        key: file.keys?.[0],
        type: file.type,
      }));
    } else if (documentData.multipleUploadedFiles.length > 1) {
      filesToPush = documentData.multipleUploadedFiles.map((file) => ({
        contentType: file.contentType,
        id: file.title ?? String(file.id),
        key: file.keys?.[0],
        type: file.type,
      }));
    }

    if (filteredUploadedFiles.length > 0) {
      const unifiedUploadedFiles: File[] = [];

      const promises = filteredUploadedFiles.map(async (field) => {
        const unifiedKeys = await unifyMultipleFilesKeys(field?.keys);

        unifiedUploadedFiles.push({
          type: field.type,
          key: unifiedKeys,
          id: field.title ?? String(field.id),
          contentType: field.contentType,
        });
      });

      await Promise.all(promises);

      filesToPush = [...filesToPush, ...unifiedUploadedFiles];
    }

    const files: File[] = [];

    if (filesToPush?.length > 0) {
      for (const uploadedMultipleFileUnified of filesToPush) {
        files.push({
          type: uploadedMultipleFileUnified?.type,
          key: uploadedMultipleFileUnified?.key,
          id: uploadedMultipleFileUnified?.id,
          contentType: uploadedMultipleFileUnified?.contentType,
        });
      }
    } else if (documentData.multipleUploadedFiles.length > 0) {
      for (const uploadedMultipleFileUnified of documentData.multipleUploadedFiles) {
        files.push({
          contentType: uploadedMultipleFileUnified.contentType,
          id: uploadedMultipleFileUnified.title ?? String(uploadedMultipleFileUnified.id),
          key: uploadedMultipleFileUnified.keys?.[0],
          type: uploadedMultipleFileUnified.type,
        });
      }
    }

    if (documentData.uploadedFiles.length) {
      for (const uploadedFile of documentData.uploadedFiles) {
        files.push({
          type: uploadedFile?.type,
          key: uploadedFile?.key,
          id: String(uploadedFile?.id),
          contentType: uploadedFile?.contentType,
        });
      }
    }

    if (options.sdkFiles) {
      const ddFrontPreview = documentDetector.getResultKey(documentSides.front);
      const ddBackPreview = documentDetector.getResultKey(documentSides.back);
      const ddBothPreview = documentDetector.getResultKey(documentSides.both);

      const pflPreview = passiveFaceLiveness.getImagePreview(true);
      const pflVideoFileName = passiveFaceLiveness.getVideoFileName();

      const iProovPflPreview = livenessIProov.getImagePreview();

      const livenessClearSaleImage = livenessClearSale.getImageUrl();

      if (ddFrontPreview && !ddBothPreview) {
        files.push({
          type: `${documentData.selectedDocumentType}_FRONT`,
          key: documentDetector.getResultKey(documentSides.front),
          bucket: documentDetector.getResultBucket(documentSides.front),
        });
      }
      if (ddBackPreview) {
        files.push({
          type: `${documentData.selectedDocumentType}_BACK`,
          key: documentDetector.getResultKey(documentSides.back),
          bucket: documentDetector.getResultBucket(documentSides.back),
        });
      }
      if (ddBothPreview) {
        files.push({
          type: `${documentData.selectedDocumentType}_BOTH`,
          key: documentDetector.getResultKey(documentSides.both),
          bucket: documentDetector.getResultBucket(documentSides.both),
        });
      }
      if (pflPreview) {
        files.push({
          type: "SELFIE",
          data: pflPreview,
          videoFileName: pflVideoFileName,
        });
      }
      if (livenessClearSaleImage) {
        files.push({
          type: "SELFIE",
          data: livenessClearSaleImage,
        });
      }
      if (iProovPflPreview) {
        files.push({
          type: "SELFIE",
          data: iProovPflPreview,
        });
      }
    }

    return files;
  };

  const getPJAttributes = async (): Promise<PJAttributes> => {
    const userIp = await getIPAddress();

    const { creditReportId, businessIdentityId } = companyData.companySearchedSelected.kybServices || {};
    const kybServices =
      creditReportId || businessIdentityId ? { kybServices: companyData.companySearchedSelected.kybServices } : {};

    return {
      idType: generalData.idType,
      ...(kybServices as CompanyKybServices),
      companyId: companyData.companyId,
      manualCompanyName: companyData.companyName,
      manualCompanyNumber: companyData.companyNumber,
      manualCompanyCountryCode: companyData.companyCountry,
      cnpj: companyData.companyCnpj.replace(/[./-]/g, ""),
      officialName: companyData.companyOfficialName.toUpperCase(),
      fantasyName: companyData.companyFantasyName.toUpperCase(),
      phoneNumber: companyData.companyPhoneNumber,
      qsa: companyData.companyQsaInfo.length
        ? companyData.companyQsaInfo.map((qsaPartner, index) => {
            const isMain = qsaPartner.main;
            return {
              main: isMain,
              name:
                qsaPartner.main && personData?.personName
                  ? personData?.personName?.toUpperCase()
                  : qsaPartner.name.toUpperCase(),
              nameForReferenceCompany: isMain ? companyData?.nameForReferenceCompany?.toUpperCase() : "",
              cpf: isMain ? personData.personCpf : qsaPartner.cpf,
              email: qsaPartner.email,
              phoneNumber: qsaPartner.phone,
              dataIndex: companyData.companyQsaOriginal.findIndex((element) => element.cpf === qsaPartner.cpf),
              /* attorney flow */
              attorney: isMain
                ? {
                    isAttorney: qsaPartner?.attorney || personData?.personAttorney || false,
                    qsaName: companyData?.referenceAttorney?.name?.toUpperCase() ?? "",
                    qsaTaxId: companyData?.referenceAttorney?.cpf?.toUpperCase() ?? "",
                    type: companyData?.partnerOrigin ?? companyData.partnerOrigin,
                  }
                : {},
            };
          })
        : null,
      address: {
        state: companyData.companyAddressStateUf || companyData.companyAddressState,
        city: companyData.companyAddressCity,
        zipCode: companyData.companyAddressZipCode,
        street: companyData.companyAddressStreet,
        neighborhood: companyData.companyAddressNeighborhood,
        number: companyData.companyAddressNumber,
        complement: companyData.companyAddressComplement.toString().trim(),
      },
      employee: !!companyData.companyEmployeeName
        ? {
            name: companyData.companyEmployeeName.toUpperCase(),
            occupation: companyData.companyEmployeeOccupation?.toUpperCase(),
            email: companyData.companyEmployeeEmail,
            phoneNumber: companyData.companyEmployeePhoneNumber,
          }
        : null,
      ...(companyData.isAnnuallyIncome
        ? {
            annuallyBilling: companyData.companyIncome,
          }
        : companyData.isMonthlyIncome
        ? {
            monthlyBilling: companyData.companyIncome,
          }
        : {}),
      ...(!!toJS(customFormData) && { customForms: toJS(customFormData) }),
      acceptedTermsData: Object.values(userData.acceptedTerms).map((term) => ({
        name: term.name,
        link: term.link,
        datetime: term.datetime,
        userAgent: term.userAgent,
        userIp,
      })),
    };
  };

  const getPFAttributes = async (): Promise<PFAttributes> => {
    const userIp = await getIPAddress();
    return {
      error: {
        exceededAttemptsError: Boolean(passiveFaceLiveness.isExceededAttemptsError()),
      },
      idType: generalData.idType,
      personId: personData.personId,
      name: personData.personName.toUpperCase(),
      cpf: personData.personCpf.replace(/\./gi, "")?.replace(/-/gi, ""),
      email: personData.personEmail,
      phoneNumber: personData.personPhoneNumber,
      birthDate: personData.personBirthDate,
      motherName: personData.personMotherName.toUpperCase(),
      fatherName: personData.personFatherName.toUpperCase(),
      rg: personData.personRg,
      rgIssuingAuthority: personData.personRgIssuingAuthority,
      rgIssueDate: personData.personRgIssueDate,
      issueDate: personData.personRgIssueDate,
      rgIssueState: personData.personRgIssueState,
      nit: personData.personNit,
      plate: personData.personLicensePlate,
      attorney: personData.personAttorney || false,
      address: Boolean(personData.personAddressStateUf || personData.personAddressState)
        ? {
            country: personData.personAddressCountry,
            state:
              personData.personAddressStateUf && personData.personAddressStateUf !== ""
                ? personData.personAddressStateUf
                : personData.personAddressState,
            city: personData.personAddressCity,
            zipCode: personData.personAddressZipCode.replace(/\./gi, "").replace(/-/gi, ""),
            street: personData.personAddressStreet,
            neighborhood: personData.personAddressNeighborhood,
            number: personData.personAddressNumber,
            complement: personData.personAddressComplement?.toString().trim(),
          }
        : null,
      PFPFData: pfpfData.PFPFEmail
        ? {
            cpf: pfpfData.PFPFCpf,
            name: pfpfData.PFPFName,
            email: pfpfData.PFPFEmail,
            phoneNumber: pfpfData.PFPFPhoneNumber,
          }
        : null,
      ...(personData.isAnnuallyIncome
        ? {
            annuallyBilling: personData.personIncome,
          }
        : personData.isMonthlyIncome
        ? {
            monthlyBilling: personData.personIncome,
          }
        : {}),
      ...(!!toJS(customFormData) && { customForms: toJS(customFormData) }),
      acceptedTermsData: Object.values(userData.acceptedTerms).map((term) => ({
        name: term.name,
        link: term.link,
        datetime: term.datetime,
        userAgent: term.userAgent,
        userIp,
      })),
      faceAuthenticator: faceAuth.isOnTemplate()
        ? {
            personId,
            isMatch: faceAuth.getIsMatch(),
          }
        : faceAuthIProov.isOnTemplate()
        ? {
            personId,
            isMatch: faceAuthIProov.getIsMatch(),
          }
        : undefined,
    };
  };

  const finishHandler = async () => {
    let finalPostId = "";
    let requestId = "";

    try {
      dispatch({
        type: "SET_LOADING",
      });

      const pjFinalPost = {
        metadata: getMetadata(),
        files: await getFiles(),
        attributes: await getPJAttributes(),
        flowId: generalData.flowId,
      };

      const pfFinalPost = {
        metadata: getMetadata(),
        files: await getFiles({
          sdkFiles: true,
        }),
        attributes: await getPFAttributes(),
        flowId: generalData.flowId,
      };

      let shouldSendPFPost = false;

      if (navigationStore.haveStep("DD")) {
        const ddFrontPreview = documentDetector.getResultKey(documentSides.front);
        const ddBackPreview = documentDetector.getResultKey(documentSides.back);
        const ddBothPreview = documentDetector.getResultKey(documentSides.both);

        const isBRDocument = documentData.documentIssuedCountry === "BR";
        const isBothSideCapture = documentDetector.isBothSideCapture();
        const hasFrontAndBackSides = documentData.isDoubleSided;

        if (isBothSideCapture && !ddBothPreview) {
          throw new Error(`MISSING_ATTRIBUTES_DD: ddBothPreview: ${ddBothPreview}`);
        }

        if (isBRDocument && !isBothSideCapture && (!ddFrontPreview || !ddBackPreview)) {
          throw new Error(`MISSING_ATTRIBUTES_DD: ddFrontPreview: ${ddFrontPreview}, ddBackPreview: ${ddBackPreview}`);
        }

        if (!isBRDocument && hasFrontAndBackSides && (!ddFrontPreview || !ddBackPreview) && !ddBothPreview) {
          throw new Error(
            `MISSING_ATTRIBUTES_DD: isBRDocument: ${isBRDocument}, ddFrontPreview: ${ddFrontPreview}, ddBackPreview: ${ddBackPreview}, ddBothPreview: ${ddBothPreview}`,
          );
        }

        if (!isBRDocument && !hasFrontAndBackSides && !ddFrontPreview && !ddBothPreview) {
          throw new Error(
            `MISSING_ATTRIBUTES_DD: isBRDocument: ${isBRDocument}, ddFrontPreview: ${ddFrontPreview}, ddBothPreview: ${ddBothPreview}`,
          );
        }

        shouldSendPFPost = true;
      }

      if (navigationStore.haveStep("PFL")) {
        const pflPreview = passiveFaceLiveness.getImagePreview(true);
        const isExceededAttemptsError = passiveFaceLiveness.isExceededAttemptsError();

        if (!pflPreview && !isExceededAttemptsError) {
          throw new Error(
            `MISSING_ATTRIBUTES_PFL: pflPreview: ${pflPreview}, isExceededAttemptsError: ${isExceededAttemptsError}`,
          );
        }
        shouldSendPFPost = true;
      }

      if (navigationStore.haveStep("LIVENESS_IPROOV")) {
        const livenessIProovPreview = livenessIProov.getImagePreview();

        if (!livenessIProovPreview) {
          throw new Error(`MISSING_ATTRIBUTES_LIVENESS_IPROOV: livenessIProovPreview: ${livenessIProovPreview}`);
        }
        shouldSendPFPost = true;
      }

      if (navigationStore.haveStep("FA")) {
        const clientsTenantIds = JSON.parse(process.env.REACT_APP_FA_CUSTOM_WORKFLOW_TENANTS || "").tenantIds;
        const isFaCustomWorkflowTenant = clientsTenantIds.some(
          (id: string) => templateData?.tenantId && id.includes(templateData.tenantId),
        );
        const faceAuthHasResult = faceAuth.hasResults();
        if (!faceAuthHasResult && !isFaCustomWorkflowTenant) {
          throw new Error(
            `MISSING_ATTRIBUTES_FA: faceAuthHasResult: ${faceAuthHasResult}, isFaCustomWorkflowTenant: ${isFaCustomWorkflowTenant}`,
          );
        }
        shouldSendPFPost = true;
      }

      if (navigationStore.haveStep("FACE_AUTH_IPROOV")) {
        const faceAuthIproovHasResult = faceAuthIProov.hasResults();
        if (!faceAuthIproovHasResult) {
          throw new Error(`MISSING_ATTRIBUTES_FACE_AUTH_IPROOV: faceAuthIproovHasResult: ${faceAuthIproovHasResult}`);
        }
        shouldSendPFPost = true;
      }

      if (navigationStore.haveStep("LIVENESS_CLEARSALE")) {
        const livenessClearSaleImage = livenessClearSale.getImageUrl();

        if (!livenessClearSaleImage) {
          throw new Error(`MISSING_ATTRIBUTES_LIVENESS_CLEARSALE: livenessClearSaleImage: ${livenessClearSaleImage}`);
        }
        shouldSendPFPost = true;
      }

      if (
        (navigationStore.haveStep("COMPANY_ADDRESS") || navigationStore.haveStep("DISPATCH_ADDRESS")) &&
        !pfFinalPost.attributes.address &&
        !pjFinalPost.attributes.address
      ) {
        throw new Error(
          `MISSING_ATTRIBUTES_ADDRESS: pfAddress: ${pfFinalPost.attributes.address}, pjAddress: ${pjFinalPost.attributes.address}`,
        );
      }

      // if (navigationStore.haveStep("USING_TERMS") || navigationStore.haveStep("DOCUMENT_TYPE")) {
      //   if (Object.values(userData.acceptedTerms).length === 0) {
      //     throw new Error("MISSING_ATTRIBUTES");
      //   }
      // }

      if (
        personData.checkData() ||
        !!toJS(customFormData) ||
        !!documentData.uploadedFiles.length ||
        !!documentData.multipleUploadedFiles.length
      ) {
        shouldSendPFPost = true;
      }

      pjFinalPost.attributes = cleanObject(pjFinalPost.attributes) as PJAttributes;
      pfFinalPost.attributes = cleanObject(pfFinalPost.attributes) as PFAttributes;

      let finalPostToken = token;

      if (onboardingType === "PJ" || onboardingType === "PJ_QSA") {
        const request = await sendFinalPost(pjFinalPost, finalPostToken);

        if (request.error) {
          throw new Error("PJ_FINAL_POST_ERROR");
        }

        finalPostId = request.response?.id;
        requestId = request.response?.requestId;

        const qsaPFPostToken = request.response.qsa?.find(
          (qsaPartner: { main: boolean }) => qsaPartner.main,
        )?.onboardingToken;

        if (qsaPFPostToken) {
          finalPostToken = qsaPFPostToken;
        }
      }

      if (shouldSendPFPost && (onboardingType === "PF" || (onboardingType === "PJ" && finalPostToken !== token))) {
        const request = await sendFinalPost(pfFinalPost, finalPostToken);

        if (request.error) {
          throw new Error("PF_FINAL_POST_ERROR");
        }

        finalPostId = request.response?.id;
        requestId = request.response?.requestId;
      }

      if (faceAuth.isOnTemplate()) {
        await emitEvent({
          code: "FINISH_FACE_AUTH",
          detail: {
            isMatch: faceAuth.getIsMatch(),
            personId,
          },
        });
      }

      if (faceAuthIProov.isOnTemplate()) {
        await emitEvent({
          code: "FINISH_FACE_AUTH",
          detail: {
            isMatch: faceAuthIProov.getIsMatch(),
            personId,
          },
        });
      }

      logAnalyticsEventInfo(analyticsEvents.FINISHED, {
        apiResponse: {
          requestId: requestId,
          executionId: finalPostId,
        },
      });

      dispatch({
        type: "SET_SUCCESS",
      });

      if (finalPostId) {
        if (props.postEventMessage) {
          startLoader({
            heading: t(`${I18N_BASE_PATH}.hooks.loader.photosSent`, "Fotos enviadas com sucesso"),
            subheading: t(
              `${I18N_BASE_PATH}.hooks.loader.waitForLoading`,
              "Aguarde o carregamento para a próxima etapa",
            ),
          });
        }
        await emitEvent({ code: "ONBOARDING_FINISHED", response: { executionId: finalPostId } });
      }
    } catch (error) {
      logAnalyticsEventInfo(analyticsEvents.FINISHED_ERROR, {
        apiResponse: {
          requestId: requestId,
          executionId: finalPostId,
        },
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
      dispatch({
        type: "SET_ERROR",
        errorData: {
          reason: error,
        },
      });
    }
  };

  useEffect(() => {
    finishHandler();
  }, []);

  return state;
}
